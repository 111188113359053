<template>
    <v-card-actions class="px-10 py-4">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.stop="$emit('submit')">
        {{ $t("Save") }}
      </v-btn>
    </v-card-actions>
</template>
<script>
export default {};
</script>
