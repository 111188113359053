import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  props: ['data', 'accountType'],
  mounted() {
    // set initial values
    Object.keys(this.form).forEach((key) => {
      if (this.data && this.data[key]) {
        this.form[key] = this.data[key];
      }
    });
  },
  computed: {
    ...mapGetters(['loading']),
    isBusiness() {
      return this.accountType === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS;
    },
    isIndividual() {
      return !this.isBusiness;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('user-settings/payout-method', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch(
            'message',
            this.$t('Podaci za plaćanje sacuvani - dodaj tekst za ovo'),
          );
          this.$emit('submit');
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
  },
};
