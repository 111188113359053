<template>
    <inquiries :url="'/my-travel/inquries'"></inquiries>
</template>

<script>
import Inquiries from './Inquiries.vue';

export default {
  components: {
    Inquiries,
  },
  mounted() {
    this.$title = this.$t('Inquiries');
  },
};

</script>
