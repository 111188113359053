<template>
    <main-layout :title="$t('Activity Log')">
      <template v-slot:body>
        <activity-log/>
      </template>
    </main-layout>
</template>

<script>
import ActivityLog from '@/components/activity-log/ActivityLog.vue';

export default {
  components: {
    ActivityLog,
  },
  mounted() {
    this.$title = this.$t('Activity Log');
  },
};

</script>
