<template>
  <main-layout
    :title="$t('Account Settings')"
    :tabs="tabs">
  </main-layout>
</template>
<script>
import { mapGetters } from 'vuex';
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import AccountSettings from '../components/user-settings/AccountSettings.vue';
import ChangePassword from '../components/user-settings/ChangePassword.vue';
import Payout from '../components/user-settings/Payout.vue';

export default {
  mixins: [ViewAsAdminMixin],
  mounted() {
    this.tabs.push(
      {
        route: 'user-settings.account',
        name: 'Account',
        component: AccountSettings,
        title: 'Panel Account SEO Title',
        infos: [
          {
            title: 'Infos.AccountSettings.title',
            description: 'Infos.AccountSettings.description',
            icon: 'green-energy',
            youtube: 'https://www.youtube.com/embed/WM8lbSfU0dU',
          },
        ],
      },
    );
    if (!this.isEmailVerified) {
      const accountSettingsTab = this.tabs.find((tab) => tab.name === 'Account');
      if (accountSettingsTab) {
        accountSettingsTab.infos.push(
          {
            title: 'Infos.VerifyEmail.title',
            description: '',
            icon: 'green-energy',
            youtube: 'https://www.youtube.com/embed/zOEwmEfrGcU',
          },
        );
      }
    }
    if (this.viewAsAdmin === false) {
      this.tabs.push({
        route: 'user-settings.change-password',
        name: 'Change Password',
        component: ChangePassword,
        title: 'Panel Change Password SEO Title',
        infos: [
          {
            title: 'Infos.ChangePassword.title',
            description: 'Infos.ChangePassword.description',
            icon: 'green-energy',
            youtube: 'https://www.youtube.com/embed/aPjE7PB787A',
          },
        ],
      });
    }
    this.tabs.push({
      route: 'user-settings.payouts',
      name: 'Payouts',
      component: Payout,
      title: 'Panel Payouts SEO Title',
      infos: [
        {
          title: 'Infos.Payout.title',
          description: 'Infos.Payout.description',
          icon: 'green-energy',
          youtube: 'https://www.youtube.com/embed/sUAvJkzVr5A',
        },
      ],
    });
  },
  computed: {
    ...mapGetters(['user']),
    isEmailVerified() {
      if (!this.user.email_verified_at) {
        return false;
      }
      return this.user.email_verified_at !== null;
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
};
</script>
