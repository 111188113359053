<template>
    <modal
      :show="true"
      @close="$emit('close')"
      @save="submit"
      :title="$t('Western Union')"
      :description="$t('Western Union hint')"
    >
    <v-container>
      <v-row>
        <v-col cols="12" md="6" >
      <div class="justify-center w-100 mt-2 px-2 mx-2 mb-5">
        <v-text-field
            v-model="form.full_name"
            :error-messages="firstError('form.full_name')"
            type="text"
            class="mt-2"
            outlined
            :label="$t('Full name')"
            :placeholder="$t('Full name')"
            :hint="$t('PayoutForm.Western Union.full_name_hint')"
            persistent-hint
            min="1"
        />

        <v-text-field
            v-model="form.city"
            :error-messages="firstError('form.city')"
            type="text"
            class="mt-2"
            outlined
            :label="$t('City')"
            :placeholder="$t('City')"
            :hint="$t('City hint')"
            persistent-hint
            min="1"
        />
        <v-text-field
            v-model="form.address"
            :error-messages="firstError('form.address')"
            type="text"
            class="mt-2"
            outlined
            :label="$t('Address')"
            :placeholder="$t('Placeholders.address')"
            :hint="$t('PayoutForm.Western Union.address_hint')"
            persistent-hint
            min="1"
        />

        <vue-tel-input-vuetify
          outlined
          :preferred-countries="['rs', 'ba', 'me', 'hr', 'si', 'de', 'mk', 'ch']"
          :valid-characters-only="true"
          :error-messages="firstError('form.phone')"
          @input="onPhoneInput"
          :hint="phone.number.international"
          :value="form.phone"
          persistent-hint
          :label="$t('Phone')"
        />

      </div>
       </v-col>
          <v-col cols="12" md="6" >
            <info
              title="Infos.Western Union.title"
              description="Infos.Western Union.description"
              icon="green-energy"
            />
          </v-col>
        </v-row>
      </v-container>
    </modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import FormValidationMixin from '@/lib/FormValidationMixin';
import PaymentMethodMixin from '@/components/payout-methods/PaymentMethodMixin';
import Info from '@/components/Info.vue';

export default {
  props: ['data'],
  mixins: [validationMixin, FormValidationMixin, PaymentMethodMixin],
  components: {
    Info,
  },
  validations: {
    form: {
      full_name: {
        required,
      },
      city: {
        required,
      },
      address: {
        required,
      },
      phone: {
        validPhoneNumber() {
          return this.phone.valid === true;
        },
        required,
      },
    },
  },
  data() {
    return {
      form: {
        full_name: null,
        city: null,
        address: null,
        phone: null,

        type: 'western_union',
      },
      phone: {
        number: {},
        valid: true,
      },
    };
  },
  methods: {
    onPhoneInput(formattedNumber, phone) {
      this.phone = phone;
      this.form.phone = phone.number.e164;
    },
  },
};
</script>
