<template>
  <main-layout
  :containerFluid="true">
    <template v-slot:body>
      <h1>{{ count }} ads found for {{ categoryConst }}</h1>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <!-- <th>Category</th> -->
          <th>Subcategory</th>
          <th>TAGS</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ad in ads" :key="ad.id">
          <td>{{ ad.id }}</td>
          <td>
            <a
              :href="getAdSinglePagePreviewUrl(ad)"
              target="_blank"
            >
              {{ ad.name }}
            </a>
            <br />
            <router-link
              :to="{ name: 'AdForm', params: {
                id: ad.id.toString(),
                categoryConst: ad.category.const,
              }}"
            >
              # {{ad.id}}
            </router-link>
            <br/>
            <div style="max-width: 600px;overflow: hidden; overflow-x: auto;">
              <div style="display: flex;" class="gap-2 w-full whitespace-nowrap">
                <div
                  v-for="(img, uuid) in ad.gallery" :key="uuid"
                  class="inline"
                >
                  <v-img
                    v-if="img.preview_url"
                    :src="img.preview_url"
                    :lazy-src="img.preview_url"
                    max-height="70"
                    max-width="100"
                  ></v-img>
                </div>
              </div>
            </div>
          </td>
          <!-- <td class="pl-10">{{ ad.category.name['sr'] }}</td> -->
           <td>
            <select @change="onSubcategoryChange(ad.id, $event.target.value, ad.name)" style="border:1px solid #ddd;">
              <option
                v-for="subcategory in subcategories[ad.category.id]"
                :key="subcategory.id"
                :value="subcategory.id"
                :selected="subcategory.id === ad.subcategory_id"
                >{{ subcategory.name.sr }}</option>
            </select>
           </td>
          <td>
            {{ ad.tags.filter(tag => [98, 99, 100, 107, 108].includes(tag.id)).map(tag => tag.name['sr']).join(', ') }}
          </td>
        </tr>
      </tbody>
    </table>
    </template>
  </main-layout>
</template>
<style scoped>
table thead th,
table tbody td{
  border-bottom: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  padding: 8px;
}
</style>
<script>
import axios from 'axios';
import ApartmentMixin from '@/lib/ApartmentMixin';

export default {
  mixins: [ApartmentMixin],
  props: {
    categoryConst: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      ads: [],
      subcategories: [],
      count: 0,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      const urlParams = new URLSearchParams(window.location.search);
      axios
        .get(`panel/ads/get-ads-with-categories/${this.categoryConst}?${urlParams.toString()}`)
        .then((ads) => {
          this.ads = ads.data;
          this.$store.dispatch('loading', false);
        });

      axios
        .get(`panel/ads/get-ads-with-categories-count/${this.categoryConst}?${urlParams.toString()}`)
        .then((count) => {
          this.count = count.data;
          this.$store.dispatch('loading', false);
        });

      axios
        .get('panel/ads/get-subcategories')
        .then((subcategories) => {
          this.subcategories = subcategories.data;
          this.$store.dispatch('loading', false);
        });
    },
    onSubcategoryChange(adId, subcategoryId, adName) {
      axios.post('panel/ads/update-ad-subcategory', { adId, subcategoryId })
        .then((response) => {
          this.$store.dispatch('message', `Subcategory updated to "${response.data.name.sr}" for ad "${adName}"`);
          console.log(response);
        })
        .catch((error) => {
          console.error('Error updating subcategory:', error);
        });
    },
  },
};
</script>
