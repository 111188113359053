<template>
    <modal
      :show="true"
      @close="$emit('close')"
      @save="submit"
      :title="$t('Serbia RSD')"
      :description="$t('Serbia RSD hint')"
    >
    <v-container>
      <v-row>
        <v-col cols="12" md="6" >
      <div class="justify-center w-100 mt-2 px-2 mx-2 mb-5">

        <v-text-field
          v-model="form.full_name"
          :error-messages="firstError('form.full_name')"
          type="text"
          class="mt-2"
          outlined
          :label="isIndividual ? $t('Full name') : $t('Business name')"
          :hint="isIndividual
            ? $t('Hints.full_name_on_account')
            : $t('Business name hint')"
          persistent-hint
          min="1"
        />

        <v-text-field
          v-model="form.city"
          :error-messages="firstError('form.city')"
          type="text"
          class="mt-2"
          outlined
          :label="$t('City')"
          :placeholder="$t('City')"
          :hint="$t('City hint')"
          persistent-hint
          min="1"
        />

        <v-text-field
          v-model="form.address"
          :error-messages="firstError('form.address')"
          type="text"
          class="mt-2"
          outlined
          :label="$t('Address')"
          :placeholder="$t('Placeholders.address')"
          :hint="$t('Hints.address')"
          persistent-hint
          min="1"
        />
        <v-text-field
          v-model="form.account_number"
          :error-messages="firstError('form.account_number')"
          type="text"
          class="mt-2 hide-spin-button"
          outlined
          :label="$t('Account number')"
          placeholder="160000000021612549"
          :hint="$t('PayoutForm.Serbia RSD.account_number_hint')"
          persistent-hint
          maxlength="18"
          counter
          min="1"
        />
        <v-text-field
          v-model="form.bank_name"
          :error-messages="firstError('form.bank_name')"
          type="text"
          class="mt-2"
          outlined
          :label="$t('Bank name')"
          :hint="$t('Hints.bank_name')"
          persistent-hint
          min="1"
        />
      </div>
       </v-col>
          <v-col cols="12" md="6" >
            <info
              title="Infos.Serbia RSD.title"
              description="Infos.Serbia RSD.description"
              icon="green-energy"
            />
          </v-col>
        </v-row>
      </v-container>
    </modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import FormValidationMixin from '@/lib/FormValidationMixin';
import PaymentMethodMixin from '@/components/payout-methods/PaymentMethodMixin';
import Info from '@/components/Info.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, PaymentMethodMixin],
  components: {
    Info,
  },
  validations: {
    form: {
      full_name: {
        required,
      },
      address: {
        required,
      },
      city: {
        required,
      },
      account_number: {
        minLength: minLength(18),
        maxLength: maxLength(18),
        required,
      },
      bank_name: {
        required,
      },
    },
  },
  computed: {
    // accountNumberHint() {
    //   if (!this.form.account_number) {
    //     return this.$t('PayoutForm.Serbia RSD.account_number_hint');
    //   }
    //   if (this.form && this.form.account_number && this.form.account_number.length === 11) {
    //     return 'sada ima 11 cifara';
    //   }
    //   return 'asdfsadf';
    // },
  },
  data() {
    return {
      form: {
        full_name: null,
        city: null,
        address: null,
        account_number: null,
        bank_name: null,

        type: 'serbia_rsd',
      },
    };
  },
};
</script>
