<template>
  <v-card outlined rounded :loading="loading">
    <v-card-text class="pa-10">
      <div class="d-flex justify-space-between align-center">
        <h2>{{ $t('Change Password Title') }}</h2>
        <help-center-link topic="change-password" link-text="Kako podesiti?"/>
      </div>
      <p class="grey--text text-body-1 text--grey darken-1 mb-6 mt-2">
        {{ $t('Change Password Subtitle') }}
      </p>
      <v-text-field
        outlined
        v-model="form.password_old"
        :error-messages="firstError('form.password_old')"
        counter="25"
        :label="$t('Current Password')"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        outlined
        v-model="form.password"
        :error-messages="firstError('form.password')"
        counter="25"
        :label="$t('New Password')"
        :type="showPassword ? 'text' : 'password'"
      />
      <v-text-field
        outlined
        v-model="form.password_confirmation"
        :error-messages="firstError('form.password_confirmation')"
        counter="25"
        :label="$t('Confirm Password')"
        :type="showPassword ? 'text' : 'password'"
      />
    </v-card-text>
    <v-divider/>
    <CardActions @submit="submit"/>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';
import CardActions from '@/components/CardActions.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  mixins: [validationMixin, FormValidationMixin],
  components: {
    CardActions,
    HelpCenterLink,
  },
  validations: {
    form: {
      password_old: {
        required,
        txtMinLen: minLength(6),
      },
      password: {
        required,
        txtMinLen: minLength(6),
      },
      password_confirmation: {
        required,
        txtMinLen: minLength(6),
        txtSameAsPassword: sameAs('password'),
      },
    },
  },
  data() {
    return {
      form: {
        password_old: undefined,
        password: undefined,
        password_confirmation: undefined,
      },
      showPasswordOld: false,
      showPassword: false,
      showPasswordConfirm: false,
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['user', 'loading']),
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('user-settings/change-password', {
          ...this.form,
        })
        .then(() => {
          this.clearForm();
          this.$store.dispatch('message', this.$t('Password Changed'));
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    clearForm() {
      this.form = {
        password_old: undefined,
        password: undefined,
        password_confirmation: undefined,
      };
      this.$store.dispatch('clearErrors');
      this.$v.$reset();
    },
  },
  destroyed() {
    this.clearForm();
  },
};
</script>
