<template>
  <main-layout
    :title="$t('My Travel')"
    :tabs="tabs"
    :hide-alerts="true"
    :containerFluid="true">
  </main-layout>
</template>
<script>
import { mapGetters } from 'vuex';
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import MyTravelInquiries from './MyTravelInquiries.vue';
import MyTravelReservation from './MyTravelReservation.vue';

export default {
  mixins: [ViewAsAdminMixin],
  mounted() {
    this.tabs.push({
      route: 'my-travel.reservations',
      name: 'Reservations',
      component: MyTravelReservation,
      title: 'Panel Payouts SEO Title',
    });
    this.tabs.push(
      {
        route: 'my-travel.inquiries',
        name: 'Inquiries',
        component: MyTravelInquiries,
        title: 'Panel Account SEO Title',
      },
    );
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      tabs: [],
    };
  },
};
</script>
