<template>
    <main-layout :hide-alerts="true" :containerFluid="true">
      <template v-slot:header>
        <v-toolbar-title class="d-flex justify-space-between align-center">
          <h1 class="text-truncate font-weight-bold text-h4">{{$t('Reservations')}}</h1>

          <!-- only show for owner's reservations -->
          <filter-ads
            v-if="$route.name === 'reservations'"
            @update="filter.ads = $event; load();"
            :value="filter.ads"
            :offsetPositionLeft="true"
            :status-filter="['pending', 'pending_fix', 'listed', 'unlisted']"
          />
        </v-toolbar-title>
      </template>
      <template v-slot:body>
        <div
          v-if="(reservations && !reservations.length) && !isLoading"
          class="pa-3 d-flex align-center"
        >
          <v-icon>
            mdi-calendar-remove-outline
          </v-icon>
          <p class="mb-0 ml-2 text-subtitle-1">
            {{ $t('You do not have any reservations') }}.
          </p>
        </div>

        <template v-if="reservations && reservations.length">
          <reservations-in-table  :reservations="reservations"/>
          <pagination
            :resource="pagination"
            :overwrite-path="url"
            @load="load"
          />
        </template>

        <reservation-preview-modal/>
      </template>
    </main-layout>
</template>

<script>
import axios from 'axios';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import ReservationsInTable from '@/components/reservations/ReservationsInTable.vue';
import Pagination from '@/components/Pagination.vue';
import FilterAds from '@/components/Filters/FilterAds.vue';

export default {
  props: {
    url: {
      required: false,
      type: String,
      default: '/calendar/reservations/me',
    },
  },
  components: {
    ReservationPreviewModal,
    ReservationsInTable,
    Pagination,
    FilterAds,
  },
  data() {
    return {
      reservations: [],
      filter: {
        ads: [],
      },
    };
  },
  mounted() {
    this.$title = this.$t('Reservations');
    this.load();
  },
  methods: {
    load(url = this.url) {
      this.$store.dispatch('loading', true);
      axios
        .get(url, {
          params: this.filter,
        })
        .then(({ data }) => {
          this.pagination = data;
          this.reservations = data.data && data.data.length ? data.data : data;
          // console.log('stigao reservations', data);
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.log('Error 23: ', error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.loading;
    },
  },
};

</script>
