<template>
    <modal
      :show="true"
      @close="$emit('close')"
      @save="submit"
      :title="$t('IBAN')"
      :description="$t('IBAN hint')"
    >
    <v-container>
      <v-row>
        <v-col cols="12" md="6" >
            <div class="justify-center w-100 mt-2 px-2 mx-2 mb-5">
              <!-- <h1 v-if="isIndividual">isIndividual</h1> -->
              <v-text-field
                v-model="form.full_name"
                :error-messages="firstError(`form.full_name`)"
                type="text"
                class="mt-2"
                outlined
                :label="isIndividual ? $t('Full name') : $t('Business name')"
                :hint="isIndividual
                  ? $t('Hints.full_name_on_account')
                  : $t('Business name hint')"
                persistent-hint
                min="1"
              />

              <v-text-field
                v-model="form.city"
                :error-messages="firstError('form.city')"
                type="text"
                class="mt-2"
                outlined
                :label="$t('City')"
                :placeholder="$t('City')"
                :hint="$t('City hint')"
                persistent-hint
                min="1"
              />

              <v-text-field
                v-model="form.address"
                :error-messages="firstError('form.address')"
                type="text"
                class="mt-2"
                outlined
                :label="$t('Address')"
                :placeholder="$t('Placeholders.address')"
                :hint="$t('Hints.address')"
                persistent-hint
                min="1"
              />

              <v-text-field
                v-model="form.account_number"
                :error-messages="firstError('form.account_number')"
                type="text"
                class="mt-2"
                outlined
                :label="$t('Account number')"
                placeholder="RS35260005601001611379"
                :hint="$t('PayoutForm.Iban.account_number_hint')"
                persistent-hint
                min="1"
                counter
              />

              <v-text-field
                v-model="form.swift"
                :error-messages="firstError('form.swift')"
                type="text"
                class="mt-2"
                outlined
                label="SWIFT"
                :placeholder="$t('Placeholders.swift')"
                :hint="$t('PayoutForm.Iban.swift_hint')"
                persistent-hint
                min="1"
              />

              <v-text-field
                v-model="form.bank_name"
                :error-messages="firstError('form.bank_name')"
                type="text"
                class="mt-2"
                outlined
                :label="$t('Bank name')"
                :hint="$t('Hints.bank_name')"
                persistent-hint
                min="1"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" >
            <info
              title="Infos.Iban.title"
              description="Infos.Iban.description"
              icon="green-energy"
            />
            <info
              class="mt-3"
              title="Infos.Iban.title2"
              description="Infos.Iban.description2"
              icon="green-energy"
            />
          </v-col>
        </v-row>
      </v-container>
    </modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import FormValidationMixin from '@/lib/FormValidationMixin';
import PaymentMethodMixin from '@/components/payout-methods/PaymentMethodMixin';
import Info from '@/components/Info.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, PaymentMethodMixin],
  components: {
    Info,
  },
  validations: {
    form: {
      full_name: {
        required,
      },
      city: {
        required,
      },
      address: {
        required,
      },
      account_number: {
        required,
      },
      swift: {
        required,
      },
      bank_name: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        full_name: null,
        city: null,
        address: null,
        account_number: null,
        swift: null,
        bank_name: null,

        type: 'iban',
      },
    };
  },
};
</script>
