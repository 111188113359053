<template>
    <reservations :url="'/my-travel/reservations'"></reservations>
</template>

<script>
import Reservations from './Reservations.vue';

export default {
  components: {
    Reservations,
  },
  mounted() {
    this.$title = this.$t('Reservations');
  },
};

</script>
