<template>
    <main-layout :title="$t('Inquiries')" :hide-alerts="true" :containerFluid="true">
      <template v-slot:header>
        <v-col class="py-2">
          <help-center-link
            topic="respond-to-inquiries"
            link-text="Kako odgovarati na upite?"
            v-if="$route.name === 'inquiries'"
          />
        </v-col>
      </template>
      <template v-slot:body>
        <div
          v-if="!inquries.length && !isLoading"
          class="pa-3 d-flex align-center"
        >
          <v-icon>
            mdi-calendar-remove-outline
          </v-icon>
          <p class="mb-0 ml-2 text-subtitle-1">
            {{ $t('You do not have any inquiries') }}.
          </p>
        </div>

        <template v-if="inquries.length">
          <inquries-in-table  :inquries="inquries" @inquiryToReservationSaved="load"/>
          <pagination
            :resource="pagination"
            :overwrite-path="url"
            @load="load"
          />
        </template>

        <inqury-preview-modal @reload="load"/>
        <reservation-preview-modal/>
      </template>
    </main-layout>
</template>

<script>
import axios from 'axios';
import InquryPreviewModal from '@/components/inquries/InquryPreviewModal.vue';
import InquriesInTable from '@/components/inquries/InquriesInTable.vue';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import Pagination from '@/components/Pagination.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';

export default {
  props: {
    id: {
      required: false,
      type: String,
    },
    url: {
      required: false,
      type: String,
      default: '/calendar/inquries/me',
    },
  },
  components: {
    InquryPreviewModal,
    InquriesInTable,
    ReservationPreviewModal,
    Pagination,
    HelpCenterLink,
  },
  data() {
    return {
      inquries: [],
      pagination: {},
    };
  },
  mounted() {
    this.$title = this.$t('Inquiries');

    this.load();
  },
  methods: {
    load(url = this.id ? `calendar/inquries/${this.id}` : this.url) {
      this.$store.dispatch('loading', true);
      axios
        .get(url)
        .then(({ data }) => {
          // if we need only for single inquiry ( for admins from emails)
          this.pagination = data;
          this.inquries = this.id
            ? [data]
            : data.data;
          // console.log('stigao inquries', data);
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.log('ERror 23: ', error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.loading;
    },
  },
};

</script>
