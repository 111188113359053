<template>
    <div>
      <v-card outlined rounded :loading="loading">
        <v-card-text class="pa-10 pb-4">
          <h2>{{ $t('Account Settings Title') }}</h2>
          <p class="grey--text text-body-1 text--grey darken-1 mb-6 mt-2">
            {{ $t('Account Settings Subtitle') }}
          </p>

          <p>{{ $t('Profile photo') }}</p>
          <media-library-attachment
            v-if="user.media_avatar"
            :key="Object.keys(user.media_avatar)[0]"
            class="attachment-avatar mb-7 "
            name="attachment"
            @change="onChangeAttachment"
            :after-upload="submitAttachment"
            :initial-value="user.media_avatar"
            :validation-errors="attachmentErrors"
            :validation-rules="{
              accept: ['image/png', 'image/jpeg'],
              maxSizeInKB: 1024*5,
              minSizeInKB: 10
            }"
            :upload-domain="uploadDomain"
            :translations="{ ...translations, selectOrDragMax: this.$t('Upload profile photo') }"
          />

          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
          <v-text-field
            outlined
            v-model="form.first_name"
            :error-messages="firstError('form.first_name')"
            :label="$t('First Name')"
          />
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
              <v-text-field
                outlined
                v-model="form.last_name"
                :error-messages="firstError('form.last_name')"
                :label="$t('Last Name')"
              />
                </v-col>
          </v-row>
          <div class="" v-if="user.email">
              <v-alert
                v-if="!isEmailVerified"
                class="mt-0 "
                border="left"
                text
                :type="!verifyEmailResend ? 'info' : 'success'"
                dense
              >
                <template v-if="!verifyEmailResend">
                  {{ $t("Email not verified")}}
                  <a href @click.prevent.stop="sendEmailVerificationNotification"
                    >{{ $t("Click here to resend verification email") }}</a>
                  <br>

                  <help-center-link topic="verify-email" link-text="Kako verifikovati email?" class="mt-3" />
                </template>
                <template v-else>
                  {{ $t("Email Verification send to your email") }} <strong>{{user.email}}</strong>.
                  <br/>
                  {{ $t("Check your email and click on the link to verify your email")}}
                  <br/>
                  {{ $t("In case you did not receive an email") + " - "}}
                  <span
                    @click.stop.prevent="sendEmailVerificationNotification"
                    class="text-decoration-underline cursor-pointer font-weight-bold text-uppercase"
                  >{{ $t("click here") }}</span>
                  {{ $t("so that we can send you a verification message again") }}
                </template>

              </v-alert>
              <youtube-iframe
                v-if="$vuetify.breakpoint.smAndDown && !isEmailVerified"
                :url="'https://www.youtube.com/embed/zOEwmEfrGcU'"
                class="mb-4"
              />
              <v-text-field
                outlined
                v-model="form.email"
                :error-messages="firstError('form.email')"
                :label="$t('Email')"
                disabled
              />
          </div>
          <v-text-field
            v-else
            outlined
            v-model="form.email"
            :error-messages="firstError('form.email')"
            label="Email"
            type="email"
          ></v-text-field>

          <v-text-field
            v-if="user.phone"
            outlined
            v-model="form.phone"
            :error-messages="firstError('form.phone')"
            :label="$t('Phone')"
            disabled
          />
          <vue-tel-input-vuetify
            v-else
            outlined
            :preferred-countries="['rs', 'ba', 'me', 'hr', 'si', 'de', 'mk', 'ch']"
            :valid-characters-only="true"
            :error-messages="firstError('form.phone')"
            @input="onPhoneInput"
            :value="form.phone ? form.phone : ''"
            :hint="phone.number.international"
            persistent-hint
            :label="$t('Phone')"
          />

          <div class='py-3' >
            <div class="d-flex justify-space-between align-center">
              <div class="subtitle-1 font-weight-bold">{{$t('ID card')}}</div>
              <help-center-link topic="verify-government-id" link-text="Kako verifikovati?"/>
            </div>
            <div class="d-flex align-center justify-space-between">
              <div>
                <div class="grey--text text-body-2 text--grey darken-1 mb-6 mt-2 pr-5 mr-5">
                  <span v-if="hasGovermentId">
                    {{
                      isGovermentIdVerified
                      ? $t('The data from the ID card has been correctly added and verified')
                      : $t('You have successfully added ID pictures')
                    }}
                  </span>
                  <span v-else>
                    {{$t('Add pictures of your ID to verify your account')}}
                  </span>
                </div>

                  <div class="grey--text">
                    <div v-if="hasGovermentId">
                      <v-icon
                        class="cursor-pointer"
                        color="green"
                      >
                        {{
                          isGovermentIdVerified
                            ? 'mdi-check-circle'
                            : 'mdi-check'
                        }}
                      </v-icon>
                      {{
                        isGovermentIdVerified
                          ? $t('Verified')
                          : $t('Under review')
                      }}
                    </div>
                    <div v-else>
                      {{$t('Not uploaded')}}
                    </div>
                </div>
              </div>
              <div class="pl-5 ml-5">
                <router-link
                  :to="viewAsAdmin
                  ? {name: 'GovernmentId', query: { viewAsAdmin: viewAsAdmin }}
                  : {name: 'GovernmentId'}"
                  class="ml-2 cursor-pointer text-decoration-underline text--link"
                >{{ hasGovermentId ? $t('Edit') : $t('Add')}}</router-link>
              </div>
            </div>
          </div>

        </v-card-text>
        <router-link :to="{name: 'DeleteAccount'}">
          <v-btn text color="error" class="ml-6 mb-4 text-body-2 text-capitalize">
            {{ $t('Delete your account') }}
          </v-btn>
        </router-link>

        <v-divider/>
        <CardActions @submit="submit" v-if="viewAsAdmin === false"/>
      </v-card>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import CardActions from '@/components/CardActions.vue';
import YoutubeIframe from '@/components/YoutubeIframe.vue';
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import MediaLibraryMixin from '@/lib/MediaLibraryMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, MediaLibraryMixin, ViewAsAdminMixin],
  components: {
    CardActions,
    YoutubeIframe,
    HelpCenterLink,
  },
  validations: {
    form: {
      first_name: {
        required,
        txtMinLen: minLength(2),
      },
      last_name: {
        required,
        txtMinLen: minLength(2),
      },
      email: {
        email,
        required,
        txtMinLen: minLength(2),
      },
      phone: {
        validPhoneNumber() {
          if (this.user.phone) {
            return true;
          }
          return this.phone.valid === true;
        },
      },
    },
  },
  data() {
    return {
      form: {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        phone: undefined,
      },
      user: {},

      attachment: undefined,
      attachmentErrors: {},

      phone: {
        number: {},
      },
      verifyEmailResend: false,
    };
  },
  mounted() {
    // this.form.first_name = this.user.first_name;
    // this.form.last_name = this.user.last_name;
    // this.form.email = this.user.email;
    // this.form.phone = this.user.phone;
    this.load();
  },
  computed: {
    ...mapGetters(['loading']),
    isEmailVerified() {
      if (!this.user.email_verified_at) {
        return false;
      }
      return this.user.email_verified_at !== null;
    },
    isGovermentIdVerified() {
      return Boolean(this.user.government_id_verified_at !== null);
    },
    hasGovermentId() {
      if (this.user.media_government_id
        && this.user.media_government_id.front
        && Object.keys(this.user.media_government_id.front).length
        && this.user.media_government_id.back
        && Object.keys(this.user.media_government_id.back).length
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get(`user-settings/account-settings${this.viewAsAdmin ? `?viewAsAdmin=${this.viewAsAdmin}` : ''}`)
        .then(({ data }) => {
          if (data) {
            this.form.first_name = data.first_name;
            this.form.last_name = data.last_name;
            this.form.email = data.email;
            this.form.phone = data.phone;
            this.user = data;

            this.$echo.private(`users.${this.user.id}`)
              .listen('UserUpdated', (e) => {
                this.$store.dispatch('updateUser', e.user);
                this.user = e.user;// this is only for admins...
                this.form.first_name = e.user.first_name;
                this.form.last_name = e.user.last_name;
                this.form.email = e.user.email;
                this.form.phone = e.user.phone;
              });
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('user-settings/account-settings', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Account Details updated'));
          this.$store.dispatch('clearErrors');

          // If user does not have email ( login from facebook )
          if (!this.user.email && this.form.email) {
            this.sendEmailVerificationNotification();
          }
          // this.$store.dispatch('updateUser', data.user);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    sendEmailVerificationNotification() {
      this.$store.dispatch('loading', true);
      axios
        .post('/email/verification-notification')
        .then(() => {
          this.$store.dispatch('message', this.$t('Verification email sent'));
          this.$store.dispatch('loading', false);
          this.verifyEmailResend = true;
        })
        .catch((data) => {
          this.$store.dispatch('setErrors', { message: [data.response.data.message] });
          this.$store.dispatch('loading', false);
        });
    },
    onPhoneInput(formattedNumber, phone) {
      this.phone = phone;
      this.form.phone = phone.number.e164;
    },

    onChangeAttachment(media) {
      this.attachment = media;
    },
    submitAttachment() {
      this.$store.dispatch('loading', true);
      axios
        .post('/user-settings/media/attachment', {
          media: this.attachment,
        })
        .then(() => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Profile photo changed'));
          // this.$store.dispatch('updateUser', data.user);
        })
        .catch((error) => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
